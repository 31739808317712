<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/invoice.png" alt="" />
        <span class="center">发票管理</span>
        <img src="../../assets/right.png" alt="" />
        <span class="center">发票信息管理</span>
      </div>
      <div style="display: flex; align-items: center">
        <span class="seach_text">关键字:</span>
        <el-input
          placeholder="请输入企业名称"
          v-model="keywords"
          @input="getInvoiceList('seath')"
          size="mini"
          style="margin-right: 10px; width: 250px"
          prefix-icon="el-icon-search"
          clearable
        >
        </el-input>
      </div>
    </div>

    <el-table :data="invoiceList" style="width: 100%">
      <el-table-column label="企业信息">
        <template slot-scope="scope">
          <div style="font-size:12px;text-align:left">
            企业名称：{{ scope.row.enterpriseName }}
          </div>

          <div style="font-size:12px;text-align:left">
            統一信用代码：<span class="clickColor">{{
              scope.row.enterpriseCode
            }}</span>
          </div>
          <div style="font-size:12px;text-align:left">
            企业电话：<span class="clickColor">{{
              scope.row.enterprisePhone
            }}</span>
          </div>
          <div style="font-size:12px;text-align:left">
            开户行：{{ scope.row.enterpriseBank }}
          </div>
          <div style="font-size:12px;text-align:left">
            银行卡号：<span class="clickColor">{{
              scope.row.enterpriseBankCode
            }}</span>
          </div>
          <div style="font-size:12px;text-align:left">
            地址：{{ scope.row.enterpriseArea }}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="收件信息">
        <template slot-scope="scope">
          <div style="font-size:12px;text-align:left">
            收件人：{{ scope.row.addresseeRecipients }}
          </div>
          <div style="font-size:12px;text-align:left">
            收件人电话：<span class="clickColor">{{
              scope.row.addresseePhone
            }}</span>
          </div>
          <div style="font-size:12px;text-align:left">
            收件地址：{{ scope.row.addresseeArea }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间">
        <template slot-scope="scope">
          <div>{{ scope.row.createTime | dateVal }}</div>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <span class="clickColor"> 共{{ total }}条</span>
      <span class="clickColor" style="margin:0 10px">
        每页{{ pageSize }}条</span
      >
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { api } from '/src/api/base';
// 查询发票详情
export const invoiceInfoList = api()('invoiceInfo.list.json');
export default {
  components: {
    // ListFilter: () => import("/src/components/filter")
  },
  data() {
    return {
      keywords: '',
      invoiceList: [],
      pageNumber: 1,
      pageSize: 10,

      total: 0,
    };
  },
  created() {
    this.getInvoiceList();
  },
  methods: {
    async getInvoiceList(seath) {
      if (seath) {
        this.pageNumber = 1;
      }
      const result = await invoiceInfoList({
        keywords: this.keywords,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      });
      this.invoiceList = result.list || [];
      this.total = result.total || 0;
    },
    handleSizeChange(val) {
      this.pageNumber = val;
      this.getInvoiceList();
    },
    pageChange(val) {
      this.pageNumber = val;
      this.getInvoiceList();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getInvoiceList();
    },
    putAway() {
      this.pageNumber = 1;
      this.invoiceList = [];
      this.getInvoiceList();
    },
  },
};
</script>

<style></style>
